<template src="./NewPassword.html"></template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import loginService from "@/services/Login";

export default {
  name: "NewPassword",
  data() {
    return {
      token: "",
      password: "",
      password_verify: "",
      loading: false,
    };
  },
  validations: {
  password: {
      required,
      minLength: minLength(6),
      containsPasswordRequirement: (value) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
    },
    password_verify: {
      sameAsPassword: sameAs("password"),
    },
  },
  created() {
    this.token = this.$route.params.token;
  },
  methods: {
    async passwordRecovery() {
      try {
        this.loading = true;
        const data = {
          token: this.token,
          password: this.password,
          password_verify: this.password_verify,
        };

        const response = await loginService
          .recoverPassword(data)
          .then((res) => res.data);
        this.loading = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Nueva contraseña establecida con éxito.",
          position: "is-bottom",
          type: "is-success",
        });
        console.log(response);
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./NewPassword.scss" scoped></style>
